//
//
//
//
//

import { mapMutations } from 'vuex'
import VRuntimeTemplate from 'v-runtime-template'

export default {
	components: {
		VRuntimeTemplate
	},
	data() {
		return {
			post: this.$store.state.post,

			postcontent: (this.$store.state.post && this.$store.state.post.content ) ? ('<div>'+this.$store.state.post.content.rendered+'</div>') : '<div></div>',

			swiperOptions: {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true,
				},
				autoplay: {
					delay: 4000
				},
			},
		}
	},
	methods: {
		onSwiperRedied(swiper) {
			console.log('Swiper redied!', swiper)
		},

		onSwiperSlideChangeTransitionStart() {
			console.log('SwiperSlideChangeTransitionStart!')
		},

		onSwiperClickSlide(index, reallyIndex ) {
			console.log('Swiper click slide!', reallyIndex)
			document.getElementsByClassName("swiper")[0].classList.toggle("fullscreen")
			document.body.classList.toggle("noscroll")
		},

		getExtension(string){
			return string.split('.').pop()
		},

		addVimeoParam(docElement){
			var vimeoCollection = docElement.querySelectorAll("iframe");
			for( var i = 0; i < vimeoCollection.length; i++ ){
				vimeoCollection.item(i).classList.add("lazyload")
				if (i === 0) {
					var currentSrc = vimeoCollection.item(i).src
					var newSrc = currentSrc + '&autoplay=1&loop=1&muted=1'
					vimeoCollection.item(i).setAttribute('src', newSrc)
				}
			}
			return docElement.innerHTML
		},
		AddVideoSrc(docElement){
			var videoCollection = docElement.getElementsByTagName("video");
			var extCollection = ['mp4', 'ogv', 'webm']
			for( var i = 0; i < videoCollection.length; i++ ){
				var extension = this.getExtension(videoCollection.item(i).src)
				var filename = videoCollection.item(i).src.replace(/\.[^/.]+$/, "")
				var e = 0, len = extCollection.length;
				while (e < len) {
					if(extension!=extCollection[e]){
						var source = document.createElement('source');
						source.setAttribute('src', filename+'.'+extCollection[e]);
						videoCollection.item(i).appendChild(source)
					}
					e++
				}
			}
			return docElement.innerHTML
		},

		AddTransition(docElement){
			var transitionCollection = docElement.querySelectorAll("p,h2");
			for( var i = 0; i < transitionCollection.length; i++ ){
				if(!transitionCollection.item(i).classList.contains('no-fade'))
					transitionCollection.item(i).setAttribute('data-aos', 'fade-up')
			}
			return docElement.innerHTML
		},

		replace_content(){
			console.log('replace_content----------------------------');
			var domParser = new DOMParser();
			var htmlString = this.post.content ? this.post.content.rendered : '';
			var docElement = domParser.parseFromString(htmlString, "text/html").documentElement;
			this.postcontent = this.AddVideoSrc(docElement)

			// var domParser = new DOMParser();
			// var htmlString = this.postcontent;
			// var docElement = domParser.parseFromString(htmlString, "text/html").documentElement;
			// this.postcontent = this.AddTransition(docElement)

			var domParser = new DOMParser();
			var htmlString = this.postcontent;
			var docElement = domParser.parseFromString(htmlString, "text/html").documentElement;
			this.postcontent = '<div>'+this.addVimeoParam(docElement.getElementsByTagName("BODY")[0])+'</div>'
		},

		htmlToElements(html) {
			var template = document.createElement('template');
			template.innerHTML = html;
			return template.content.childNodes;
		}

	},
	async mounted() {
		console.log('monted content ')
		this.replace_content()
		setTimeout(function () {
			var wp_block_img = document.getElementsByClassName("wp-block-image")
			console.log('wp_block_img');
			console.log(wp_block_img);
			if(wp_block_img.length){
				console.log('wp_block_img');
				for (var i=0; i < wp_block_img.length; i++) {
					wp_block_img[i].onclick = function(){
						console.log('click');
						this.classList.toggle("fullscreen")
						document.body.classList.toggle("noscroll")
					}
				};
			}
		}, 1000);
	},
	watch: {
		'$store.state.post': function() {
			setTimeout(function () {
				if(document.getElementsByTagName("video").length){
					console.log('ya video on change post')
					var video = document.getElementsByTagName("video")[0];
					var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;
					if (!isPlaying) {
						console.log('not playing ='+video.getElementsByTagName("source")[0].src)
						video.play();
					}
				}
				// var wp_block_img = document.getElementsByClassName("wp-block-image")
				// console.log('wp_block_img');
				// console.log(wp_block_img);
				// if(wp_block_img.length){
				// 	console.log('wp_block_img');
				// 	for (var i=0; i < wp_block_img.length; i++) {
				// 		wp_block_img[i].onclick = function(){
				// 			console.log('click');
				// 			this.classList.toggle("fullscreen")
				// 			document.body.classList.toggle("noscroll")
				// 		}
				// 	};
				// }
			}, 1000);
		}
	},
}
