//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {
			hover: false,
		};
	},
  props: {
    post: { type: Object }
  },
  methods: {
		addVimeoParam(oembed){
			var newSrc = oembed.split('src="')[1].split(/[ >]/)[0]
			var newSrcParam= newSrc + '&autoplay=1&loop=1&muted=1&controls=0'
			newSrcParam = newSrcParam.replace('"&', '&')
			oembed = oembed.replace(/(src=).*?(&)/,'$1' + newSrcParam + '$2')
			oembed =  oembed.replace('title=', ' class="lazyload" title=')
			return oembed
		},
	},
}
